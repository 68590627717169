* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-content {
  /* background-color: red; */
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 123;
}

.navbar-nav li {
  margin-right: 20px;
  font-weight: 600;
}

.btn-primary-v2 {
  background-color: #8b4091;
  color: #fff;
  padding: 10px 30px;
  border-radius: 2px;
  font-weight: 600;
}

.btn-primary-v2:hover {
  background-color: #6c3170;
  color: #fff;
}

.banner-content {
  background-image: url("./asset/img/banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 625px;
  color: #fff;
}

.info-content {
  margin: 75px 0;
  background-color: #f1f1f1;
  padding: 50px 0;
}

.info-card-title {
  font-size: 42px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.info-card-paragraph {
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.info-card-content {
  margin-top: 100px;
}

.single-info-content {
  padding: 100px 0;
  /* background-color: #f4f1ea; */
}

.single-info-title {
  font-size: 64px;
  font-weight: 400;
  letter-spacing: 1.5px;
  width: 60%;
  margin: auto;
  text-align: center;
}

.contact-content {
  margin-bottom: 75px;
  background-color: #8b4091;
}

.contact-content-container {
  position: relative;
  /* background-color: #359dab; */
  /* min-height: 700px; */
  border-radius: 40px;
}

.contact-content-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://sqy7rm.media.zestyio.com/Tree-Asset.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 0.1;
  z-index: 0;
}

.contact-content-container * {
  position: relative;
  z-index: 1;
}

.form-label {
  font-weight: 500;
  font-size: 15px;
  color: #753579;
}

.form-control {
  background-color: transparent;
  border: 1px solid #aaa;
  color: #753579 !important;
  border-radius: 2px;
  padding: 5px 10px;
}

.form-control:focus {
  background-color: transparent;
  outline: none;
  border: 1px solid #753579;
  box-shadow: 0 0 0 .25rem #7535794a;
}

textarea {
  min-height: 150px !important;
}

.form-control::placeholder {
  color: #c0c0c0;
  opacity: 1;
  /* Firefox */
}

.contact-title {
  color: #fff;
  font-size: 46px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.contact-paragraph {
  color: #fff;
  font-size: 18px;
  letter-spacing: .5px;
}

.footer-content {
  border-top: 1px solid #efefef;
}

.footer-content p {
  font-size: 15px;
  color: #8f8f8f;
}

.about-us-content {
  background-color: #F4F1EA;
  margin-bottom: 35px;
}

.btn-light {
  border-radius: 2px;
}

.banner-card {
  background-color: #fff;
  border-radius: 2px;
  border: 5px solid #8b409126;
}

.banner-card h3{
  color: #753579;
}

@media (max-width: 992px) {
  .single-info-title {
    width: 100%;
  }

  .mobile-banner-contact{
    padding: 35px 10px;
  }

  .info-image {
    width: 100%;
    padding: 25px 0;
  }

  .info-content .text-end {
    text-align: start !important;
  }

  .about-us-content img {
    width: 100%;
    padding: 30px 0 0 0;
  }

  .banner-content .container {
    width: 100% !important;
  }

  .banner-content .info-card-title {
    font-size: 48px !important;
  }

  .single-info-content .single-info-title {
    font-size: 48px !important;
  }

  .single-info-content {
    padding: 50px 0 !important;
  }

  .info-content .single-info-title {
    font-size: 48px !important;
  }

  .mobile-contact-content {
    margin-top: 35px;
  }

  .contact-mobile img {
    width: 100%;
  }

  .contact-mobile .w-75.mx-auto {
    width: 100% !important;
  }
}